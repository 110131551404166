import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Select from 'react-select';
import { createCouncil, getAllChapters, getAllMembers } from '../../utils/api-calls';
import { rel8Pink, rel8Purple, rel8White } from '../../globals';
import { mobile } from '../../responsive';

const BackDrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const SubCon = styled.div`
  background-color: ${rel8White};
  width: 350px;
  height: 75%;
  max-height: 90%;
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;

  ${mobile({
    width: '250px',
  })}
`;

const SubConHeader = styled.p`
  font-weight: 700;
  text-align: center;
`;

const Form = styled.form`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin: 10px 0px;
`;

const FormDataComp = styled.input`
  padding: 5px 0px;
  background-color: transparent;
  border: none;
  border: 1px solid ${rel8Purple};
  border-radius: 5px;
  padding: 5px;
  color: ${rel8Purple};
  outline: none;
  &::placeholder {
    color: ${rel8Purple};
  }
`;

const SelectComp = styled(Select)`
  padding: 5px 0px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 5px;
  color: ${rel8Purple};
  outline: none;
`;

const SubConBtnHold = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
`;

const SubConBtn = styled.input`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) =>
    props.typex === 'filled' ? `${rel8Purple}` : `${rel8Pink}`};
  color: ${(props) =>
    props.typex === 'filled' ? `${rel8White}` : `${rel8Purple}`};
  cursor: pointer;
`;

const AddCouncil = ({ close }) => {
  const { register, handleSubmit, control } = useForm();
  const queryClient = useQueryClient();

  const { data: membersData } = useQuery('all-members', getAllMembers);
  const { data: chaptersData } = useQuery('all-chapters', getAllChapters);
  const chapters = chaptersData?.results || [];
  const members = membersData?.data || [];

  const { isLoading, mutate } = useMutation((data) => createCouncil(data), {
    onMutate: () => {
      toast.info('Council Creation in progress', {
        progressClassName: 'toastProgress',
        icon: false,
      });
    },
    onSuccess: () => {
      toast.success('Council Created', {
        progressClassName: 'toastProgress',
        icon: false,
      });
      queryClient.invalidateQueries('all-excos');
      close();
    },
    onError: (error) => {
      toast.error('Could not create council');
      if (error?.response?.data?.message?.error) {
        toast.error(`Message: ${error.response.data.message.error}`, {
          autoClose: 9000,
        });
      }
    },
  });

  const onSubmit = (data) => {
    const payload = {
      ...data,
      member_ids: data.member_ids ? data.member_ids.map((item) => item.value) : [],
      chapter_id: data.chapter_id ? Number(data.chapter_id.value) : null,
      can_upload_min: true, // Set the default value for the checkbox
    };
    mutate(payload);
  };

  const memberOptions = members.map((member) => ({
    value: member.id,
    label: member.full_name,
  }));

  const chapterOptions = chapters.map((chapter) => ({
    value: chapter.id,
    label: chapter.name,
  }));

  return (
    <BackDrop>
      <style>
        {`
          body {
            overflow: hidden;
          }
        `}
      </style>
      <SubCon>
        <SubConHeader>Create Councils</SubConHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormLabel>
            Name:
            <FormDataComp type="text" {...register('name', { required: true })} />
          </FormLabel>
          <FormLabel>
            About:
            <FormDataComp type="text" {...register('about')} />
          </FormLabel>
          <input type="checkbox" {...register('can_upload_min')} style={{ display: 'none' }} defaultChecked />
          <FormLabel>
            Members:
            <Controller
              name="member_ids"
              control={control}
              render={({ field }) => (
                <SelectComp
                  {...field}
                  isMulti
                  options={memberOptions}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </FormLabel>
          <FormLabel>
            Chapter:
            <Controller
              name="chapter_id"
              control={control}
              render={({ field }) => (
                <SelectComp
                  {...field}
                  options={chapterOptions}
                  onChange={(value) => {
                    field.onChange(value);
                    // console.log("Selected Chapter: ", value); // Debug log
                  }}
                />
              )}
            />
          </FormLabel>
          <SubConBtnHold>
            <SubConBtn type="submit" disabled={isLoading} value="Create" typex="filled" />
            <SubConBtn type="button" value="Cancel" onClick={close} />
          </SubConBtnHold>
        </Form>
      </SubCon>
    </BackDrop>
  );
};

export default AddCouncil;
