import styled from "styled-components";
import { rel8Pink, rel8Purple, rel8White, rel8Gray, rel8DarkPurple } from "../../../globals";
import { mobile, tablet } from "../../../responsive";

export const FundProjectDetailContainer = styled.div`
  margin-top: 67px;
  margin-left: 220px;
  padding: 50px 30px;
  background-color: ${rel8Pink};
  min-height: 100vh;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    h1 {
    font-size: 20px;
  }

  ${mobile({
    marginTop: "0px",
    marginLeft: "0px",
    padding: "20px",
  })}

  ${tablet({
    marginTop: "0px",
    marginLeft: "0px",
    padding: "30px",
  })}
`;

export const FundProjectDetailHeader = styled.h2`
  font-size: 24px;
  color: ${rel8Purple};
  margin-bottom: 20px;
  text-align: center;
`;

export const SupportCard = styled.div`
  background-color: ${rel8White};
  border: 1px solid ${rel8Gray};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }

  h3 {
    font-size: 18px;
    color: ${rel8DarkPurple};
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: ${rel8Gray};
    margin: 5px 0;
  }
`;

export const TabContent = styled.div`
  margin-top: 20px;

  h2 {
    font-size: 20px;
    color: ${rel8DarkPurple};
    margin-bottom: 15px;
    border-bottom: 2px solid ${rel8Purple};
    padding-bottom: 5px;
  }

  p {
    font-size: 16px;
    color: ${rel8Gray};
    text-align: center;
  }
`;
