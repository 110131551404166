import { CouncilContainer, CouncilHeader } from './Councils.styles';
import { ExcosTable } from "../ActionComponents/ActionComponents1";
import { deleteCouncil, getAllCouncils, getAllChapters, getAllMembers } from "../../utils/api-calls";
import Loading from "../Loading/Loading";
import {
  AddNewBtn,
  MembersSearch,
  MembersSearchBtn,
  MembersSearchCompCon,
  MembersSearchInput,
} from "../Members/Members.styles";
import { useState } from "react";
import { SearchIcon } from "../../assets/SideBar/svgs";
import AddCouncil from "../Modals/AddCouncil";
import { toast } from "react-toastify";
import { useQuery, useMutation, useQueryClient } from "react-query";

const Councils = () => {
  const [searchValue, setSearchValue] = useState("");
  const [addEvent, setAddEvent] = useState(false);
  const queryClient = useQueryClient();

  const { data: membersData } = useQuery('all-members', getAllMembers);
  const { data: chaptersData } = useQuery('all-chapters', getAllChapters);
  const chapters = chaptersData?.results || [];
  const members = membersData?.data || [];
  // console.log(chapters)
  // console.log(members)

  const displayAddEvent = () => {
    setAddEvent(!addEvent);
  };

  const { isLoading: excoLoading, isFetching: excoFetching, data: excoData, isError: excoIsError } = useQuery("all-excos", getAllCouncils, {
    refetchOnWindowFocus: false,
    select: (data) => data.data,
  });

  const { mutate } = useMutation(deleteCouncil, {
    onMutate: () => {
      toast.info("Project Delete in progress", {
        progressClassName: "toastProgress",
        icon: false,
      });
    },
    onSuccess: () => {
      toast.success("Deleted", {
        progressClassName: "toastProgress",
        icon: false,
      });
      queryClient.invalidateQueries("all-excos");
    },
    onError: (error) => {
      toast.error("Could not delete project");
      if (error?.message?.response?.data?.message?.error) {
        toast.error(`Message: ${error.message.response.data.message.error}`, {
          autoClose: 9000,
        });
      }
    },
  });

  const displayViewMoreModal = (id) => {
    mutate(id);
  };

  const handleAddOrUpdate = (event) => {
    queryClient.invalidateQueries("all-excos");
  };
  
  return (
    <>
    {addEvent && <AddCouncil close={displayAddEvent} />}
      <CouncilContainer>
        <CouncilHeader>Councils Positions</CouncilHeader>
        <MembersSearch>
          <MembersSearchCompCon>
            <MembersSearchInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
            />
            <MembersSearchBtn>
              <SearchIcon style={{ width: "15px", height: "15x" }} />
            </MembersSearchBtn>
          </MembersSearchCompCon>
          <AddNewBtn 
          onClick={displayAddEvent}
          >Add New</AddNewBtn>
        </MembersSearch>
        {excoLoading || excoFetching ? (
          <Loading loading={excoLoading || excoFetching} />
        ) : excoIsError ?
         (
          <small>Can't fetch positions</small>
        ) : (
          <ExcosTable show={addEvent}  
          // deleteFn={displayModal} 
          onUpdate={handleAddOrUpdate}
          data={excoData}
          deleteFn={displayViewMoreModal} 
          membersData={members}
          chaptersData={chapters}  
          />
        )}
      </CouncilContainer>
    </>
  );
};

export default Councils;
