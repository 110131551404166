import { useMemo } from 'react';
import { useTable } from 'react-table';
import PropTypes from 'prop-types';
import {Table as Rel8Table, TableRow, TableHead, TableData } from './Table.styles';

const Table = ({ prop_columns = [], custom_data = [] }) => {
  const columns = useMemo(() => prop_columns, [prop_columns]);
  const data = useMemo(() => custom_data, [custom_data]);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns, data
  });

  const handleRowRoute = (id) => {
    // this would send the page to a detail page
  };

  return (
    <Rel8Table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, columnIndex) => (
              <TableHead {...column.getHeaderProps()} key={columnIndex}>{column.render('Header')}</TableHead>
            ))}
          </TableRow>
        ))}
      </thead>
      <tbody>
        {rows.map((row, index) => {
          let id = row.original.id;
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()} key={index} onClick={() => handleRowRoute(id)}>
              {row.cells.map((cell, cellIndex) => (
                <TableData {...cell.getCellProps()} key={cellIndex}>{cell.render('Cell')}</TableData>
              ))}
            </TableRow>
          );
        })}
      </tbody>
    </Rel8Table>
  );
};

Table.propTypes = {
  prop_columns: PropTypes.array,
  custom_data: PropTypes.array,
};

export default Table;
