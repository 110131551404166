import styled from "styled-components";
import { rel8LightPink, rel8White } from "../globals";
import { mobile } from "../responsive";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.th`
  text-align: center;
  padding-bottom: 20px;
  border-right: 2px solid ${rel8LightPink};
`;

export const TableData = styled.td`
  padding: 10px;
  font-size: 14px;
  background-color: ${rel8White};
  text-align: center;
  border-bottom: 2px solid ${rel8LightPink};
  border-right: 2px solid ${rel8LightPink};

  ${mobile({
    padding: "2px",
  })}
`;

export const TableRow = styled.tr``;

export const TableControls = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const ArrowButton = styled.button`
  background: #007bff;
  border: none;
  color: white;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: #0056b3;
  }
`;
