import { useFieldArray, useForm } from "react-hook-form";
import { EventsHeader } from "../Events/Events.styles";
import InputWithLabel, { SelectWithInput } from "../InputWithLabel";
import { MeetingsContainer } from "../Meetings/Meetings.styles";
import { AddNewBtn } from "../Members/Members.styles";
import CustomModal from "../Modals/CustomModal";
import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { SubConBtn } from "../Button";
import { createServiceRequestApi, deleteServicesRequestApi, getServicesRequestApi } from "../../utils/api-calls";
import { toast } from "react-toastify";
import Table from '../../components/Table';
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";
import { TableControls, ArrowButton } from '../Table.styles';

const ServiceRequest = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { isLoading, data } = useQuery('getServicesRequestApi', getServicesRequestApi);
    const queryClient = useQueryClient();
    const route = useNavigate();

    const { mutate, isLoading: deleting } = useMutation(deleteServicesRequestApi, {
        onSuccess: () => {
            queryClient.invalidateQueries('getServicesRequestApi');
        }
    });
    const [currentService, setCurrentService] = useState(null);
    const [currentColumns, setCurrentColumns] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const prop_columns = [
        { Header: 'Service Name', accessor: 'service_name' },
        { Header: 'Intro Text', accessor: 'intro_text' },
        { Header: 'Amount', accessor: 'amount' },
        {
            Header: 'Edit',
            accessor: 'id',
            Cell: (tableProps) => (
                <SubConBtn onClick={(e) => {
                    e.preventDefault();
                    setCurrentService(tableProps.row.original);
                }}>
                    Edit
                </SubConBtn>
            )
        },
        {
            Header: 'Delete',
            accessor: 'id',
            id: 2,
            Cell: (tableProps) => (
                <SubConBtn onClick={(e) => {
                    e.preventDefault();
                    mutate(tableProps.row.original.id);
                }}>
                    Delete
                </SubConBtn>
            )
        },
        {
            Header: 'View',
            accessor: 'id',
            id: 4,
            Cell: (tableProps) => (
                <SubConBtn onClick={(e) => {
                    e.preventDefault();
                    route(`/service-request/member-submission/${tableProps.row.original.id}`);
                }}>
                    View
                </SubConBtn>
            )
        }
    ];

    const handleNextColumns = () => {
        setCurrentColumns((prev) => (prev + 3) % prop_columns.length);
    };

    const handlePrevColumns = () => {
        setCurrentColumns((prev) => (prev - 3 + prop_columns.length) % prop_columns.length);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <MeetingsContainer>
            <Loading loading={deleting || isLoading} />
            <EventsHeader>Service Request</EventsHeader>
            <br />
            <AddNewBtn style={{ display: 'inline-block', marginRight: 'auto' }} onClick={() => setIsOpen(true)}>Create Service</AddNewBtn>

            {isOpen && (
                <CustomModal title={'Create a new Service'} close={() => setIsOpen(false)} modalWith="600px">
                    <CreateServiceRequestForm onClose={() => setIsOpen(false)} />
                </CustomModal>
            )}

            {currentService && (
                <CustomModal title={'Edit Service'} close={() => setCurrentService(null)} modalWith="600px">
                    <CreateServiceRequestForm service={currentService} isEdit={true} onClose={() => setCurrentService(null)} />
                </CustomModal>
            )}
            <br /><br />

            {isMobile && (
                <TableControls>
                    <ArrowButton onClick={handlePrevColumns}>←</ArrowButton>
                    <ArrowButton onClick={handleNextColumns}>→</ArrowButton>
                </TableControls>
            )}

            <Table prop_columns={isMobile ? prop_columns.slice(currentColumns, currentColumns + 3) : prop_columns} custom_data={data?.results || []} />
        </MeetingsContainer>
    );
};

export default ServiceRequest;

const grid = { 'display': 'grid', gap: '10px', 'gridTemplateColumns': '1fr  1fr', 'alignItems': 'center' };
export type ServiceProp = {
    "id": number,
    "service_name": string,
    "intro_text": string,
    "fields_subbission": {
        "fields": string[]
    },
    "file_subbission": {
        "fields": string[]
    },
    "is_paid": boolean,
    "break_down_of_payment": {
        "payment": { "name": string, "amount": string }[]
    },
    "amount": string
};
type CreateServiceRequestFormProp = {
    isEdit?: boolean,
    service?: ServiceProp,
    onClose: () => void
};
const CreateServiceRequestForm = ({ isEdit, service, onClose }: CreateServiceRequestFormProp) => {
    const { register, handleSubmit, watch, setValue, control } = useForm(isEdit ? {
        defaultValues: { ...service }
    } : {});

    const { fields: textfields, append: textAppend, remove: textRemove } = useFieldArray({
        // @ts-ignore
        name: "fields_subbission.fields",
        control,
    });

    const { fields: fileFields, append: fileAppend, remove: fileRemove } = useFieldArray({
        // @ts-ignore
        name: "file_subbission.fields",
        control,
    });

    const { fields, append, remove } = useFieldArray({
        name: "break_down_of_payment.payment",
        control,
    });

    const queryClient = useQueryClient();
    const { isLoading, mutate } = useMutation(createServiceRequestApi, {
        onSuccess: () => {
            toast.success("Created Service Successfully", {
                progressClassName: "toastProgress",
                icon: false,
            });
            onClose();  // Close the modal on success
            queryClient.invalidateQueries('getServicesRequestApi');
        },
        onError: () => {
            toast.error("Something went wrong", {
                progressClassName: "toastProgress",
                icon: false,
            });
        },
    });

    useEffect(() => {
        setValue('amount', '0.00');
        setValue('break_down_of_payment', {
            // @ts-ignore
            break_down_of_payment: {
                payment: []
            }
        });

        if (service) {
            // set initial values for edit
        }
    }, [service, setValue]);

    const onSubmit = (data: any) => {
        if (isEdit) {
            // send edit request
        } else {
            mutate(data);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputWithLabel
                label="Service Name"
                name="service_name"
                required
                register={register}
            />
            <InputWithLabel
                label="Intro Text"
                name="intro_text"
                required
                register={register}
                is_textarea
            />
            <SelectWithInput
                label="Do you want this service to be paid"
                name="is_paid"
                required
                register={register}
                options={[
                    { 'name': 'Yes', 'value': 'true' },
                    { 'name': 'No', 'value': 'false' },
                ]}
            />
            {
                // @ts-ignore
                watch('is_paid') === 'true' ?
                    <>
                        <InputWithLabel
                            label="Amount Member Should Pay"
                            name="amount"
                            required
                            register={register}
                        />
                        {fields.map((field, index) => (
                            <div key={index}>
                                <div style={grid}>
                                    <InputWithLabel
                                        label="Bill Name"
                                        name={`break_down_of_payment.payment.${index}.name`}
                                        required
                                        register={register}
                                    />
                                    <InputWithLabel
                                        label="Bill Amount"
                                        name={`break_down_of_payment.payment.${index}.amount`}
                                        required
                                        register={register}
                                    />
                                    {/* @ts-ignore */}
                                    <SubConBtn typex={'filled'} onClick={e => {
                                        e.preventDefault();
                                        remove(index);
                                    }}>
                                        Delete
                                    </SubConBtn>
                                </div>
                            </div>
                        ))}
                        <SubConBtn onClick={e => {
                            e.preventDefault();
                            append({ 'name': "Bill One", 'amount': '10.00' });
                        }}>
                            Add More Billing
                        </SubConBtn>
                    </>
                    : ''
            }
            {textfields.map((field, index) => (
                <div key={index}>
                    <div style={grid}>
                        <InputWithLabel
                            label="Fields Name"
                            name={`fields_subbission.fields.${index}`}
                            required
                            register={register}
                        />
                        {/* @ts-ignore */}
                        <SubConBtn typex={'filled'} onClick={e => {
                            e.preventDefault();
                            textRemove(index);
                        }}>
                            Delete
                        </SubConBtn>
                    </div>
                </div>
            ))}
            <SubConBtn
                style={{ 'display': 'inline-block' }}
                onClick={e => {
                    e.preventDefault();
                    // @ts-ignore
                    textAppend('Full Name');
                }}>
                Add Text Field Name
            </SubConBtn>
            {fileFields.map((field, index) => (
                <div key={index}>
                    <div style={grid}>
                        <InputWithLabel
                            label="Fields Name"
                            name={`file_subbission.fields.${index}`}
                            required
                            register={register}
                        />
                        {/* @ts-ignore */}
                        <SubConBtn typex={'filled'} onClick={e => {
                            e.preventDefault();
                            fileRemove(index);
                        }}>
                            Delete
                        </SubConBtn>
                    </div>
                </div>
            ))}
            <SubConBtn style={{ 'display': 'inline-block' }} onClick={e => {
                e.preventDefault();
                // @ts-ignore
                fileAppend('Upload Birth Cert');
            }}>
                Add File Fields
            </SubConBtn>
            <br />
            {/* @ts-ignore */}
            <SubConBtn typex={'filled'}>
                {
                    isLoading ?
                        'Creating...' :
                        'Submit'
                }
            </SubConBtn>
        </form>
    );
};
