import { useEffect } from "react";
import { useState } from "react";
import { getAllChapters, deleteChapter } from "../../utils/api-calls";
import { userStore } from "../../zustand/stores";
import { ChaptersTable } from "../ActionComponents/ActionComponents1";
import { EventsList } from "../Events/Events.styles";
import Loading from "../Loading/Loading";
import AddChapter from "../Modals/AddChapter";
import RegisterUserToChapter from "../Modals/RegisterUserToChapter";
import {
  ChaptersButton,
  ChaptersButtonCon,
  ChaptersContainer,
  ChaptersHeader,
} from "./Chapters.styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const Chapters = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [createModal, showCreateModal] = useState(false);
  const [registerModal, showRegisterModal] = useState(false);
  const queryClient = useQueryClient();

  const displayCreate = () => {
    showCreateModal(!createModal);
  };

  const displayRegister = () => {
    showRegisterModal(!registerModal);
  };

  const { isLoading, isFetching, isError, data } = useQuery(
    "all-chapters",
    getAllChapters,
    {
      refetchOnWindowFocus: false,
      select: (data) => data.results,
    }
  );
  const userInfo = userStore((state) => state.user);

  const { mutate } = useMutation(deleteChapter, {
    onMutate: () => {
      toast.info("Chapter Delete in progress", {
        progressClassName: "toastProgress",
        icon: false,
      });
    },
    onSuccess: () => {
      toast.success("Deleted", {
        progressClassName: "toastProgress",
        icon: false,
      });
      queryClient.invalidateQueries("all-chapters");
    },
    onError: (error) => {
      toast.error("Could not delete chapter");
      if (error?.message?.response?.data?.message?.error) {
        toast.error(`Message: ${error.message.response.data.message.error}`, {
          autoClose: 9000,
        });
      }
    },
  });

  const displayViewMoreModal = (id) => {
    // console.log('me');
    mutate(id);
  };

  if (!userInfo?.user_type === "super_admin") {
    return (
      <ChaptersContainer>
        <ChaptersHeader>Unauthorized Admin</ChaptersHeader>
      </ChaptersContainer>
    );
  }

  return (
    <>
      {createModal && <AddChapter close={displayCreate} />}
      {registerModal && <RegisterUserToChapter close={displayRegister} />}

      <ChaptersContainer>
        <ChaptersHeader>Chapters</ChaptersHeader>

        <ChaptersButtonCon>
          <ChaptersButton onClick={displayCreate}>
            Create New Chapter
          </ChaptersButton>
          <ChaptersButton
            isUsable={isLoading ? "no" : "yes"}
            onClick={displayRegister}
          >
            Register User to Chapter
          </ChaptersButton>
        </ChaptersButtonCon>

        <EventsList>
          {isLoading || isFetching ? (
            <Loading loading={isLoading || isFetching} />
          ) : !isError ? (
            <ChaptersTable 
            deleteFn={displayViewMoreModal}
            data={data} />
          ) : (
            <small>can't fetch chapters</small>
          )}
        </EventsList>
      </ChaptersContainer>
    </>
  );
};

export default Chapters;
