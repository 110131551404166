import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { SearchIcon } from "../../assets/SideBar/svgs";
import { getAllMembers, getAllExcos } from "../../utils/api-calls";
import { MemberDashTable } from "../ActionComponents/ActionComponents1";
import DeleteMember from "../DashBoard/DeleteMember";
import Loading from "../Loading/Loading";
import Pagination from "../Pagination/Pagination";
import {
  MembersContainer,
  MembersPersonList,
  MembersPersons,
  MembersPersonTab,
  MembersSearch,
  MembersSearchBtn,
  MembersSearchCompCon,
  MembersSearchInput,
} from "./Members.styles";

const Members = () => {
  const [showModal, setModal] = useState(false);
  const [options, setOptions] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const { data: membersData, isLoading: membersLoading, isFetching: membersFetching, isError: membersError } = useQuery(
    "all-members",
    getAllMembers,
    {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
    }
  );
  console.log(membersData)

  const { data: excosData, isLoading: excosLoading, isFetching: excosFetching, isError: excosError } = useQuery(
    "all-excos",
    getAllExcos,
    {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const displayModal = () => {
    setModal(!showModal);
  };

  const searchHandler = (data) => {
    const searchPattern = new RegExp(searchValue, "i");
    return data?.filter((item) => item.email.search(searchPattern) >= 0);
  };

  const searchResult = searchHandler(options === "all" ? membersData : excosData);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const paginatedData = searchResult?.slice(firstPostIndex, lastPostIndex);

  return (
    <>
      {showModal && <DeleteMember close={displayModal} />}
      <MembersContainer>
        <MembersPersonTab typex="dues">
          <MembersPersons
            typex="dues"
            filled={options === "all" ? "show" : ""}
            onClick={() => setOptions("all")}
          >
            All Members
          </MembersPersons>
          <MembersPersons
            typex="dues"
            filled={options === "excos" ? "show" : ""}
            onClick={() => setOptions("excos")}
          >
            Excos
          </MembersPersons>
        </MembersPersonTab>

        <MembersSearch>
          <MembersSearchCompCon>
            <MembersSearchInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
            />
            <MembersSearchBtn onClick={() => searchHandler(options === "all" ? membersData : excosData)}>
              <SearchIcon style={{ width: "15px", height: "15px" }} />
            </MembersSearchBtn>
          </MembersSearchCompCon>
        </MembersSearch>

        <MembersPersonList>
          {membersLoading || excosLoading || membersFetching || excosFetching ? (
            <Loading loading={membersLoading || excosLoading || membersFetching || excosFetching} />
          ) : membersError || excosError ? (
            <small>Can't fetch {options === "all" ? "members" : "excos"}</small>
          ) : searchResult?.length <= 0 ? (
            <MemberDashTable
              show={showModal}
              deleteFn={displayModal}
              data={paginatedData}
            />
          ) : (
            <MemberDashTable
              show={showModal}
              deleteFn={displayModal}
              data={paginatedData}
            />
          )}
        </MembersPersonList>

        <Pagination
          totalPosts={searchResult?.length}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </MembersContainer>
    </>
  );
};

export default Members;
