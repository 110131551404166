import { FundProjectDetailContainer, FundProjectDetailHeader, SupportCard, TabContent  } from './styles/DetailsFundsProject';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getCashSupport, getKindSupport } from '../../utils/api-calls';
import Loading from '../Loading/Loading';
import { rel8Pink, rel8Purple, rel8White } from "../../globals";
import styled from "styled-components";

export const TabListStyled = styled(TabList)`
  display: flex;
  justify-content: space-around;
  gap: 10px;
  margin-bottom: 20px;
  list-style-type: none;
`;
export const TabStyled = styled(Tab)`
  padding: 10px 20px;
  cursor: pointer;  /* Add pointer cursor */
  background-color: ${rel8White};
  color: ${rel8Purple};
  border-radius: 5px;
  border: 1px solid ${rel8Purple};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${rel8Pink};
  }

  &.react-tabs__tab--selected {
    background-color: ${rel8Purple};
    color: ${rel8White};
  }
`;

const FundAProjectInterestedMember = () => {
  const { id } = useParams();
  const location = useLocation();

  const { heading } = location.state || {}; 

  const { isLoading: isLoadingCash, data: cashSupportData } = useQuery(["cashSupport", id], () => getCashSupport(id));
  const { isLoading: isLoadingKind, data: kindSupportData } = useQuery(["kindSupport", id], () => getKindSupport(id));

  if (isLoadingCash || isLoadingKind) {
    return <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Loading loading={true} />
            </div>;
  }

  return (
    <FundProjectDetailContainer>
      <h1>{heading}</h1>
      <FundProjectDetailHeader>Interested Members for Project</FundProjectDetailHeader>
      <Tabs>
      <TabListStyled>
            <TabStyled>Supporters in Kind</TabStyled>
            <TabStyled>Supporters in Cash</TabStyled>
          </TabListStyled>
        <TabPanel>
          <TabContent>
            <h2>Member's List</h2>
            {kindSupportData?.data && kindSupportData.data.length > 0 ? (
              kindSupportData.data.map((support, index) => (
                <SupportCard key={index}>
                  <h3>{support.heading}</h3>
                  <p><strong>About:</strong> {support.about}</p>
                  <p><strong>Member:</strong> {support.member}</p>
                  <p><strong>Delivery Date:</strong> {new Date(support.delivery_date).toLocaleDateString()}</p>
                </SupportCard>
              ))
            ) : (
              <p>No in-kind support data available</p>
            )}
          </TabContent>
        </TabPanel>
        <TabPanel>
          <TabContent>
            <h2>Member's List</h2>
            {cashSupportData?.data && cashSupportData.data.length > 0 ? (
              cashSupportData.data.map((support, index) => (
                <SupportCard key={index}>
                  <h3>{support.heading}</h3>
                  <p><strong>About:</strong> {support.about}</p>
                  <p><strong>Member:</strong> {support.member}</p>
                  <p><strong>Delivery Date:</strong> {new Date(support.delivery_date).toLocaleDateString()}</p>
                </SupportCard>
              ))
            ) : (
              <p>No cash support data available</p>
            )}
          </TabContent>
        </TabPanel>
      </Tabs>
    </FundProjectDetailContainer>
  );
};

export default FundAProjectInterestedMember;
