import styled from "styled-components";
import { rel8Pink, rel8Purple, rel8White } from "../../globals";
import { mobile } from "../../responsive";
import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { updatePublication } from '../../utils/api-calls';

const BackDrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const SubCon = styled.div`
  background-color: ${rel8White};
  width: 350px;
  max-height: 80%;
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;

  ${mobile({
    width: "250px",
  })}
`;

const SubConHeader = styled.p`
  font-weight: 700;
  text-align: center;
`;

const Form = styled.form`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin: 10px 0px;
`;

const FormDataComp = styled.input`
  padding: 5px 0px;
  background-color: transparent;
  border: none;
  border: 1px solid ${rel8Purple};
  border-radius: 5px;
  padding: 5px;
  color: ${rel8Purple};
  outline: none;
  &::placeholder {
    color: ${rel8Purple};
  }
`;

const SubConBtnHold = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
`;

const SubConBtn = styled.input`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) =>
    props.typex === "filled" ? `${rel8Purple}` : `${rel8Pink}`};
  color: ${(props) =>
    props.typex === "filled" ? `${rel8White}` : `${rel8Purple}`};
  cursor: pointer;
`;

function EditPublicationModal({ show, onClose, data, onSubmit }) {
    const [formData, setFormData] = useState({
        name: data?.name || '',
      });
    
      useEffect(() => {
        if (data) {
          setFormData({
            name: data.name || '',
          });
        }
      }, [data]);
    
      const { isLoading, mutate: updateEventMutation } = useMutation(
        (publicationData) => updatePublication(publicationData.id, publicationData),
        {
          onMutate: () => {
            toast.info("Publication update in progress", {
              progressClassName: "toastProgress",
              icon: false,
            });
          },
          onSuccess: (response) => {
            toast.success("Publication updated successfully", {
              progressClassName: "toastProgress",
              icon: false,
            });
            onSubmit(response);
            onClose();
          },
          onError: (error) => {
            toast.error(`Can't update publication: ${error.message}`);
          },
        }
      );
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        updateEventMutation({ id: data.id, ...formData });
      };
    
      if (!show) return null;
  return (
    // <div>EditPublication</div>

    <BackDrop>
              <style>
        {`
          body {
            overflow: hidden;
          }
        `}
      </style>
      <SubCon>
      <SubConHeader>Edit Publication</SubConHeader>
      <Form 
      onSubmit={handleSubmit}
      >
          <FormLabel>
            Name:
            <FormDataComp
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </FormLabel>
          <SubConBtnHold>
            <SubConBtn type="submit" value="Save" typex="filled" 
            disabled={isLoading} 
            />
            <SubConBtn type="button" value="Cancel" 
            onClick={onClose}
             />
          </SubConBtnHold>
        </Form>
      </SubCon>
    </BackDrop>
  )
}

export default EditPublicationModal