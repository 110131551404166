// GLOBAL COLORS
export const rel8Purple = "#7F02A2"; // Existing color
export const rel8White = "#FFFFFF"; // Existing color
export const rel8Black = "#000000"; // Existing color
export const rel8Pink = "#C892E314"; // Existing color
export const rel8LightPink = "#f5f5f5"; // Existing color
export const rel8Blue = "#E5E5E5"; // Existing color

// New complementary colors
export const rel8DarkPurple = "#5B0078"; // A darker shade of purple for contrast
export const rel8Gray = "#7A7A7A"; // A medium gray for text and borders
export const rel8LightGray = "#D3D3D3"; // A light gray for backgrounds or borders
export const rel8DarkPink = "#B569A8"; // A deeper shade of pink for emphasis
export const rel8SoftBlue = "#D0E1F9"; // A soft blue for backgrounds or accents
export const rel8Teal = "#008080"; // A teal color for an additional accent
export const rel8LightPurple = "#B38EDC"; // A lighter shade of purple for backgrounds or highlights
