import { useState } from "react";
import styled from "styled-components";
import {
  CancelIcon,
  EditIcon,
  EllipsesIcon,
  PlusCircleIcon,
  EditProfileIcon,
  DeleteIcon
} from "../../assets/SideBar/svgs";
import { rel8LightPink, rel8Purple, rel8White } from "../../globals";
import { mobile } from "../../responsive";
import {
  AllDuesViewMore,
  CommitteeViewMore,
  CouncilViewMore,
  ElectionAddAspirant,
  ElectionAddPosition,
  ElectionPosition,
  EventsViewMore,
  MeetingViewMore,
  MembersDashViewMore,
  MembersDuesViewMore,
  NewsViewMore,
  PublicationViewMore,
} from "./ViewMoreInfo";
import EditEventModal from '../Modals/EditEvent';
import { useNavigate } from "react-router-dom";
import EditNewsModal from "../Modals/EditNews";
import EditCouncilModal from "../Modals/EditCouncil";
import EditPublicationModal from "../Modals/EditPublication";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
export const TableBody = styled.tbody``;
export const TableRow = styled.tr``;
export const TableHead = styled.th`
  text-align: center;
  padding-bottom: 20px;
  border-right: 2px solid ${rel8LightPink};
`;
export const TableData = styled.td`
  padding: 10px;
  font-size: 14px;
  background-color: ${rel8White};
  text-align: center;
  border-bottom: 2px solid ${rel8LightPink};
  border-right: 2px solid ${rel8LightPink};

  ${mobile({
    padding: "2px",
  })}
`;
export const ElectionThemeHeader = styled.p`
  font-size: 20px;
  color: ${rel8Purple};
  margin: 30px 20px;
  text-decoration: underline;
`;

export const ActionBtns = styled.button`
  padding: 10px;
  background-color: ${rel8Purple};
  border-radius: 10px;
  border: none;
  font-size: 12px;
  cursor: pointer;
  color: ${rel8White};
  ${mobile({
    fontSize: "10px",
  })}
`;

export const NoAction = () => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableHead>Hello</TableHead>
          <TableHead>Hello</TableHead>
        </TableRow>
        <TableRow>
          <TableData>Hello Content</TableData>
          <TableData>Hello Content</TableData>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export const DeleteOnly = ({ deleteFn }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableHead>Hello</TableHead>
          <TableHead>Hello</TableHead>
        </TableRow>
        <TableRow>
          <TableData>Hello Content</TableData>
          <TableData>Hello Content</TableData>
          <TableData>
            <CancelIcon
              svgClick={deleteFn}
              style={{ cursor: "pointer", width: "25px", height: "25px" }}
            />
          </TableData>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const EditDeletOnly = ({ editFn, deleteFn }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableHead>Hello</TableHead>
          <TableHead>Hello</TableHead>
        </TableRow>
        <TableRow>
          <TableData>Hello Content</TableData>
          <TableData>Hello Content</TableData>
          <TableData>
            <EditIcon
              svgClick={editFn}
              style={{ cursor: "pointer", width: "25px", height: "25px" }}
            />
          </TableData>
          <TableData>
            <CancelIcon
              svgClick={deleteFn}
              style={{ cursor: "pointer", width: "25px", height: "25px" }}
            />
          </TableData>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export const EditDeleteWriteOnly = ({ deleteFn, editFn, writeFn }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableHead>Hello</TableHead>
          <TableHead>Hello</TableHead>
        </TableRow>
        <TableRow>
          <TableData>Hello Content</TableData>
          <TableData>Hello Content</TableData>
          <TableData>
            <PlusCircleIcon
              svgClick={writeFn}
              style={{ cursor: "pointer", width: "25px", height: "25px" }}
            />
          </TableData>
          <TableData>
            <EditIcon
              svgClick={editFn}
              style={{ cursor: "pointer", width: "25px", height: "25px" }}
            />
          </TableData>
          <TableData>
            <CancelIcon
              svgClick={deleteFn}
              style={{ cursor: "pointer", width: "25px", height: "25px" }}
            />
          </TableData>
        </TableRow>
      </TableBody>
    </Table>
  );
};

//DASHBOARD
export const MemberDashTable = ({ deleteFn, data, show }) => {
  const [selected, setSelected] = useState(null);
  return (
    <>
      {show && <MembersDashViewMore data={selected} close={deleteFn} />}
      <Table>
        <TableBody>
          <TableRow>
            <TableHead>Email</TableHead>
            <TableHead>Amount Owing</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
          {data.map((item) => {
            return (
              <TableRow key={item.id}>
                <TableData style={{ overflowWrap: "anywhere" }}>
                  {item.email}
                </TableData>
                <TableData>
                  {Number(item.amount_owing).toLocaleString("en-US")}
                </TableData>
                <TableData>
                  <EllipsesIcon
                    svgClick={deleteFn}
                    itemInfo={() => setSelected(item)}
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                  />
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

// ExcoTables
export const ExcosTable = ({ data, deleteFn, onUpdate, membersData, chaptersData }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalType, setModalType] = useState(null);

  const handleEdit = (item) => {
    // console.log("Editing item: ", item);
    setSelectedItem(item);
    setModalType("edit");
  };

  const handleViewMore = (item) => {
    setSelectedItem(item);
    setModalType("view");
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setModalType(null);
  };

  const handleUpdate = (updatedCouncil) => {
    onUpdate(updatedCouncil);
    handleCloseModal();
  };

  return (
    <>
      {modalType === "edit" && (
        <EditCouncilModal
          show={modalType === "edit"}
          onClose={handleCloseModal}
          data={selectedItem}
          onSubmit={handleUpdate}
          membersData={membersData}
          chaptersData={chaptersData}
        />
      )}

      {modalType === "view" && (
        <CouncilViewMore id={selectedItem?.id} close={handleCloseModal} />
      )}

      <Table>
        <TableBody>
          <TableRow>
            <TableHead>Council</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableData>{item.name}</TableData>
              <TableData>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ActionBtns onClick={() => handleViewMore(item)}>
                    Details
                  </ActionBtns>
                  <DeleteIcon
                    svgClick={() => deleteFn(item.id)}
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                  />
                  <EditProfileIcon
                    svgClick={() => handleEdit(item)}
                    style={{ cursor: "pointer", width: "20px", height: "20px" }}
                  />
                </div>
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export const ExcoDashTable = ({ data, deleteFn, onUpdate, membersData, chaptersData }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalType, setModalType] = useState(null);

  const handleViewMore = (item) => {
    setSelectedItem(item);
    setModalType("view");
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setModalType(null);
  };

  return (
    <>
      {modalType === "view" && (
        <CouncilViewMore id={selectedItem?.id} close={handleCloseModal} />
      )}
      <Table>
        <TableBody>
          <TableRow>
            <TableHead>Council</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableData>{item.name}</TableData>
              <TableData>
                  <ActionBtns onClick={() => handleViewMore(item)}>
                    Details
                  </ActionBtns>
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

//DUES
export const AllDuesTable = ({ deleteFn, data, show }) => {
  const [selected, setSelected] = useState(null);
  return (
    <>
      {show && <AllDuesViewMore data={selected} close={deleteFn} />}

      <Table>
        <TableBody>
          <TableRow>
            <TableHead>S/N</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Amount</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
          {data.map((item, index) => {
            return (
              <TableRow key={item.id}>
                <TableData>{index + 1}</TableData>
                <TableData>{item.Name}</TableData>
                <TableData>
                  {Number(item.amount).toLocaleString("en-US")}
                </TableData>
                <TableData>
                  <EllipsesIcon
                    svgClick={deleteFn}
                    itemInfo={() => setSelected(item)}
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                  />
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export const MemDuesTable = ({ deleteFn, data, show }) => {
  const [selected, setSelected] = useState(null);
  return (
    <>
      {show && <MembersDuesViewMore data={selected} close={deleteFn} />}

      <Table>
        <TableBody>
          <TableRow>
            <TableHead>Id</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Amount Owing</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
          {data.map((item) => {
            return (
              <TableRow key={item.id}>
                <TableData>{item.id}</TableData>
                <TableData style={{ overflowWrap: "anywhere" }}>
                  {item.email.toString()}
                </TableData>
                <TableData>
                  {Number(item.amount_owing).toLocaleString("en-US")}
                </TableData>
                <TableData>
                  <EllipsesIcon
                    svgClick={deleteFn}
                    itemInfo={() => setSelected(item)}
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                  />
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

//EVENT
export const EventsTable = ({ show, data, deleteFn, onUpdate }) => {
  const [selected, setSelected] = useState(null);
  const [modalType, setModalType] = useState(null); 

  const handleEdit = (item) => {
    setSelected(item);
    setModalType("edit");
  };

  const handleViewMore = (item) => {
    setSelected(item);
    setModalType("view");
  };

  const handleCloseModal = () => {
    setSelected(null);
    setModalType(null);
  };

  const handleUpdate = (updatedEvent) => {
    onUpdate(updatedEvent);
  };

  return (
    <>
      {modalType === "edit" && (
        <EditEventModal
          show={modalType === "edit"}
          onClose={handleCloseModal}
          data={selected}
          onSubmit={handleUpdate}
        />
      )}

      {modalType === "view" && (
        <EventsViewMore data={selected} close={handleCloseModal} />
      )}

      <Table>
        <TableBody>
          <TableRow>
            <TableHead>S/N</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Amount</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
          {data.map((item, index) => (
            <TableRow key={item.id}>
              <TableData>{index + 1}</TableData>
              <TableData style={{ overflowWrap: "anywhere" }}>
                {item.name}
              </TableData>
              {item.is_paid_event ? (
                <TableData>
                  {Number(item.amount).toLocaleString("en-US")}
                </TableData>
              ) : (
                <TableData>
                  {Number("0.000").toLocaleString("en-US")}
                </TableData>
              )}
              <TableData
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <EllipsesIcon
                  svgClick={() => handleViewMore(item)}
                  itemInfo={() => setSelected(item)}
                  style={{ cursor: "pointer", width: "25px", height: "25px" }}
                />
                <EditProfileIcon
                  svgClick={() => handleEdit(item)}
                  style={{ cursor: "pointer", width: "20px", height: "20px" }}
                />
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};


//NEWS
export const NewsTable = ({ show, data, deleteFn, onUpdate }) => {
  const [selected, setSelected] = useState(null);
  const [modalType, setModalType] = useState(null); 

  const handleEdit = (item) => {
    setSelected(item);
    setModalType("edit");
  };

  const handleViewMore = (item) => {
    setSelected(item);
    setModalType("view");
  };

  const handleCloseModal = () => {
    setSelected(null);
    setModalType(null);
  };

  const handleUpdate = (updatedEvent) => {
    onUpdate(updatedEvent);
  };

  return (
    <>
      {/* {show && <NewsViewMore data={selected} close={deleteFn} />} */}
      {modalType === "view" && (
        <NewsViewMore data={selected} 
        close={handleCloseModal}
        // close={deleteFn}
         />
      )}
      {modalType === "edit" && (
        <EditNewsModal
          show={modalType === "edit"}
          onClose={handleCloseModal}
          data={selected}
          onSubmit={handleUpdate}
        />
      )}
      <Table>
        <TableBody>
          <TableRow>
            <TableHead>S/N</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
          {data.map((item, index) => {
            return (
              <TableRow key={item.id}>
                <TableData>{index + 1}</TableData>
                <TableData>{item.name}</TableData>
                <TableData>
                  <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
                  <EllipsesIcon
                    svgClick={() => handleViewMore(item)}
                    itemInfo={() => setSelected(item)}
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                  />
                  <EditProfileIcon
                    svgClick={() => handleEdit(item)}
                    style={{ cursor: "pointer", width: "20px", height: "20px" }}
                  />
                  </div>
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

//Publication Table
// export const PublicationTable = ({ show, data, deleteFn }) => {
//   const [selected, setSelected] = useState(null);

//   return (
//     <>
//       {show && <PublicationViewMore data={selected} close={deleteFn} />}

//       <Table>
//         <TableBody>
//           <TableRow>
//             <TableHead>Id</TableHead>
//             <TableHead>Name</TableHead>
//             <TableHead>Action</TableHead>
//           </TableRow>
//           {data.map((item) => {
//             return (
//               <TableRow key={item.id}>
//                 <TableData>{item.id}</TableData>
//                 <TableData>{item.name}</TableData>
//                 <TableData>
//                   <EllipsesIcon
//                     svgClick={deleteFn}
//                     itemInfo={() => setSelected(item)}
//                     style={{ cursor: "pointer", width: "25px", height: "25px" }}
//                   />
//                 </TableData>
//               </TableRow>
//             );
//           })}
//         </TableBody>
//       </Table>
//     </>
//   );
// };

export const PublicationTable = ({ show, data, deleteFn, onUpdate }) => {
  const [selected, setSelected] = useState(null);
  const [modalType, setModalType] = useState(null); 

  const handleEdit = (item) => {
    setSelected(item);
    setModalType("edit");
  };

  const handleViewMore = (item) => {
    setSelected(item);
    setModalType("view");
  };

  const handleCloseModal = () => {
    setSelected(null);
    setModalType(null);
  };

  const handleUpdate = (updatedEvent) => {
    onUpdate(updatedEvent);
  };

  return (
    <>
      {modalType === "view" && (
        <PublicationViewMore data={selected} 
        close={handleCloseModal}
        // close={deleteFn}
         />
      )}
      {modalType === "edit" && (
        <EditPublicationModal
          show={modalType === "edit"}
          onClose={handleCloseModal}
          data={selected}
          onSubmit={handleUpdate}
        />
      )}
      <Table>
        <TableBody>
          <TableRow>
            <TableHead>S/N</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
          {data.map((item, index) => {
            return (
              <TableRow key={item.id}>
                <TableData>{index + 1}</TableData>
                <TableData>{item.name}</TableData>
                <TableData>
                  <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
                  <EllipsesIcon
                    svgClick={() => handleViewMore(item)}
                    itemInfo={() => setSelected(item)}
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                  />
                  <EditProfileIcon
                    svgClick={() => handleEdit(item)}
                    style={{ cursor: "pointer", width: "20px", height: "20px" }}
                  />
                  </div>
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

//COMMITTEE
export const CommitteeTable = ({ show, data, deleteFn }) => {
  const [selected, setSelected] = useState(null);

  return (
    <>
      {show && <CommitteeViewMore data={selected} close={deleteFn} />}
      <Table>
        <TableBody>
          <TableRow>
            <TableHead>S/N</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
          {data.map((item, index) => {
            return (
              <TableRow key={item.id}>
                <TableData>{index + 1}</TableData>
                <TableData>{item.name}</TableData>
                <TableData>
                  <EllipsesIcon
                    svgClick={deleteFn}
                    itemInfo={() => setSelected(item)}
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                  />
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

//ELECTION
export const ElectionTable = ({ data }) => {
  const [addPos, showAddPos] = useState(false);
  const [addAsp, showAddAsp] = useState(false);
  const [selected, setSelected] = useState(null);

  const displayAddPos = () => {
    showAddPos(!addPos);
  };

  const displayAddAsp = () => {
    showAddAsp(!addAsp);
  };

  return (
    <>
      {addPos && (
        <ElectionAddPosition close={displayAddPos} electionid={selected} />
      )}
      {addAsp && (
        <ElectionAddAspirant close={displayAddAsp} electionid={selected} />
      )}
      <Table>
        <TableBody>
          {data.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableData>{item.name}</TableData>
                <TableData>
                  <ActionBtns
                    onClick={() => {
                      displayAddPos();
                      setSelected(item.id);
                    }}
                  >
                    Add Position
                  </ActionBtns>
                </TableData>
                <TableData>
                  <ActionBtns
                    onClick={() => {
                      displayAddAsp();
                      setSelected(item.id);
                    }}
                  >
                    Add Aspirant
                  </ActionBtns>
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export const ElectionResultTable = ({ data }) => {
  const [showResult, setShowResult] = useState(false);
  const [selected, setSelected] = useState(null);

  const displayShowResult = () => {
    setShowResult(!showResult);
  };

  return (
    <>
      {showResult && (
        <ElectionPosition id={selected} close={displayShowResult} />
      )}
      <Table>
        <TableBody>
          {data.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableData>{item.name}</TableData>
                <TableData>{item.is_close ? "ended" : "on going"}</TableData>
                <TableData>
                  <ActionBtns
                    onClick={() => {
                      displayShowResult();
                      setSelected(item.id);
                    }}
                  >
                    Show Positions
                  </ActionBtns>
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

//CHAPTER
export const ChaptersTable = ({ data, deleteFn }) => {
  return (
    <>
      <ElectionThemeHeader>CHAPTERS</ElectionThemeHeader>
      <Table>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableData>{item.name}</TableData>
              <TableData>
                <DeleteIcon
                  svgClick={() => deleteFn(item.id)}
                  itemInfo={() => {}}
                  style={{ cursor: 'pointer', width: '20px', height: '20px' }}
                />
                </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

//FUND A PROJECT
export const FundAProjectTable = ({ data, show, deleteFn }) => {
  const navigate = useNavigate();

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableHead>S/N</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>About</TableHead>
          <TableHead>Needs</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
        {data.map((item, index) => (
          <TableRow key={item.id}>
            <TableData>{index + 1}</TableData>
            <TableData>{item.heading}</TableData>
            <TableData>{item.about.slice(0, 10)}...</TableData>
            <TableData>{item.what_project_needs.join(', ')}</TableData>
            <TableData>
              <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', gap: '5px' }}>
                <button
                  style={{ color: '#8A2BE2', background: 'none', border: 'none', cursor: 'pointer' }}
                  // onClick={() => navigate('/fund_a_project/' + item.id)}
                  onClick={() => navigate(`/fund_a_project/${item.id}`, { state: { heading: item.heading } })}
                >
                  View
                </button>
                <DeleteIcon
                  svgClick={() => deleteFn(item.id)}
                  itemInfo={() => {}}
                  style={{ cursor: 'pointer', width: '25px', height: '25px' }}
                />
              </div>
            </TableData>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

// MEETINGS
export const MeetingsTable = ({ show, data, deleteFn }) => {
  const [selected, setSelected] = useState(null);

  return (
    <>
      {show && <MeetingViewMore data={selected} close={deleteFn} />}
      <Table>
        <TableBody>
          <TableRow>
            <TableHead>S/N</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
          {data.map((item, index) => (
            <TableRow key={item.id}>
              <TableData>{index + 1}</TableData>
              <TableData>{item.name}</TableData>
              <TableData>
                <EllipsesIcon
                  svgClick={deleteFn}
                  itemInfo={() => setSelected(item)}
                  style={{ cursor: "pointer", width: "25px", height: "25px" }}
                />
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
