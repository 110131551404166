import { useMutation} from "react-query";
import { sendMemberShipPushNoficationApi } from "../utils/api-calls";
import { SubConBtn } from "./Button";
import { DashBoardMemberCon } from "./DashBoard/DashBoard.styles";
import MemberDetBox from "./DashBoard/MemberDetBox";
import { EventsHeader } from "./Events/Events.styles";
import { MeetingsContainer } from "./Meetings/Meetings.styles";
import Loading from "./Loading/Loading";
import { AddNewBtn } from "./Members/Members.styles";
import { useState } from "react";
import CustomModal from "./Modals/CustomModal";
import { FormDataComp, FormLabel } from "./Modals/AddMeeting";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const NotificationPage = () => {
  const [sectorModal, setSectorModal] = useState(false);

  const dashboardData = [
    {
      header: 'Membership Level',
      subheader: (
        <SubConBtn onClick={() => setSectorModal(true)}>
          Send
        </SubConBtn>
      ),
    },
  ];

  return (
    <MeetingsContainer>
      <EventsHeader>
        Send Notifications
      </EventsHeader>
      <br />
      <DashBoardMemberCon>
        {dashboardData.map((item, index) => (
          <MemberDetBox
            cirColor={'#7f02a2'}
            key={index}
            data={{
              header: item.header,
              subheader: item.subheader,
            }}
          />
        ))}
      </DashBoardMemberCon>
      {sectorModal && (
        <CustomModal title={'Membership Level'} close={() => setSectorModal(false)}>
          <SendSectorNotifcationForm setSectorModal={setSectorModal} />
        </CustomModal>
      )}
    </MeetingsContainer>
  );
};

export default NotificationPage;

const SendSectorNotifcationForm = ({setSectorModal}) => {


  const { isLoading, mutate } = useMutation(sendMemberShipPushNoficationApi, {
    onSuccess: () => {
      toast.success("Notification Sent!!", {
        progressClassName: "toastProgress",
        icon: false,
      });
      setSectorModal(false);
    },
  });

  const onSubmit = (value: any) => {
    mutate(value);
  };

  const { register, handleSubmit } = useForm({});

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Loading loading={isLoading} />

      <FormLabel>
        Title:
        <FormDataComp
          type={"text"}
          {...register("title", { required: true })}
        />
      </FormLabel>
      <br />
      <FormLabel>
        Message:
        <textarea
          {...register("body", { required: true })}
        />
      </FormLabel>
      <br />
      <AddNewBtn type="submit">
        Submit
      </AddNewBtn>
    </form>
  );
};
