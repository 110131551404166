import { useMemo, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { updateCouncil, getAllChapters, getAllMembers } from '../../utils/api-calls';
import styled from "styled-components";
import { rel8Pink, rel8Purple, rel8White } from "../../globals";
import { mobile } from "../../responsive";
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

const BackDrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const SubCon = styled.div`
  background-color: ${rel8White};
  width: 350px;
  max-height: 80%;
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;

  ${mobile({
    width: "250px",
  })}
`;

const SubConHeader = styled.p`
  font-weight: 700;
  text-align: center;
`;

const Form = styled.form`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin: 10px 0px;
`;

const FormDataComp = styled.input`
  padding: 5px 0px;
  background-color: transparent;
  border: none;
  border: 1px solid ${rel8Purple};
  border-radius: 5px;
  padding: 5px;
  color: ${rel8Purple};
  outline: none;
  &::placeholder {
    color: ${rel8Purple};
  }
`;

const SelectComp = styled(Select)`
  padding: 5px 0px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 5px;
  color: ${rel8Purple};
  outline: none;
`;

const SubConBtnHold = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
`;

const SubConBtn = styled.input`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) =>
    props.typex === "filled" ? `${rel8Purple}` : `${rel8Pink}`};
  color: ${(props) =>
    props.typex === "filled" ? `${rel8White}` : `${rel8Purple}`};
  cursor: pointer;
`;

const EditCouncilModal = ({ show, onClose, data, onSubmit }) => {
  const { register, handleSubmit, control, setValue } = useForm();
  const [selectedMembers, setSelectedMembers] = useState([]);

  const { data: membersData } = useQuery('all-members', getAllMembers);
  const { data: chaptersData } = useQuery('all-chapters', getAllChapters);

  const memoizedChapters = useMemo(() => {
    return chaptersData?.results || [];
  }, [chaptersData]);

  const memoizedMembers = useMemo(() => {
    return membersData?.data || [];
  }, [membersData]);

  useEffect(() => {
    if (data) {
      setValue('name', data.name || '');
      setValue('about', data.about || '');

      const chapterOption = memoizedChapters.find((chapter) => chapter.name === data.chapter);
      setValue('chapter_id', chapterOption ? { value: chapterOption.id, label: chapterOption.name } : null);

      if (Array.isArray(data.member)) {
        const memberOptions = memoizedMembers
          .filter((member) => data.member.includes(member.full_name))
          .map((member) => ({
            value: member.id,
            label: member.full_name,
          }));

        setSelectedMembers(memberOptions);
        setValue('member_ids', memberOptions);
      }
    }
  }, [data, setValue, memoizedChapters, memoizedMembers]);

  const onSubmitForm = (formData) => {
    const memberIds = selectedMembers.map((item) => parseInt(item.value, 10));

    const payload = {
      id: data.id,
      name: formData.name,
      about: formData.about,
      member_ids: memberIds,
      chapter: formData.chapter_id ? Number(formData.chapter_id.value) : null,
    };

    updateEventMutation(payload);
  };

  const { isLoading, mutate: updateEventMutation } = useMutation(
    (eventData) => updateCouncil(eventData.id, eventData),
    {
      onMutate: () => {
        toast.info("Council update in progress", {
          progressClassName: "toastProgress",
          icon: false,
        });
      },
      onSuccess: (response) => {
        toast.success("Council updated successfully", {
          progressClassName: "toastProgress",
          icon: false,
        });
        onSubmit(response);
        onClose();
      },
      onError: (error) => {
        toast.error(`Can't update council: ${error.message}`);
      },
    }
  );

  if (!show) return null;

  const memberOptions = memoizedMembers.map((member) => ({
    value: member.id,
    label: member.full_name,
  }));

  const chapterOptions = memoizedChapters.map((chapter) => ({
    value: chapter.id,
    label: chapter.name,
  }));

  return (
    <BackDrop>
      <SubCon>
        <SubConHeader>Edit Council</SubConHeader>
        <Form onSubmit={handleSubmit(onSubmitForm)}>
          <FormLabel>
            Name:
            <FormDataComp
              type="text"
              {...register('name', { required: true })}
            />
          </FormLabel>
          <FormLabel>
            About:
            <FormDataComp
              type="text"
              {...register('about')}
            />
          </FormLabel>
          <FormLabel>
            Members:
            <Controller
              name="member_ids"
              control={control}
              render={({ field }) => (
                <SelectComp
                  {...field}
                  isMulti
                  options={memberOptions}
                  onChange={(value) => {
                    setSelectedMembers(value);
                    field.onChange(value);
                  }}
                  value={selectedMembers}
                />
              )}
            />
          </FormLabel>
          <FormLabel>
            Chapter:
            <Controller
              name="chapter_id"
              control={control}
              render={({ field }) => (
                <SelectComp
                  {...field}
                  options={chapterOptions}
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                />
              )}
            />
          </FormLabel>
          <SubConBtnHold>
            <SubConBtn type="submit" value="Save" typex="filled" disabled={isLoading} />
            <SubConBtn type="button" value="Cancel" onClick={onClose} />
          </SubConBtnHold>
        </Form>
      </SubCon>
    </BackDrop>
  );
};

export default EditCouncilModal;
